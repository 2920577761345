import React from 'react'
import styles from './banner.module.css'
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu";

export const Banner = (props) => {

  return (
    <div className={styles.banner}>

      <img alt='' src="https://resources.swmc.com/swmc-images/WholesaleLending/6dots.svg"
        className={styles.leftdot} />

      <img alt='' src="https://resources.swmc.com/swmc-images/WholesaleLending/dot4.svg"
        className={styles.rightdot} />
      <div className={styles.UserWayAccessibilitydesktopmobile}>
        <UserWayAccessibilityMenu color="white" width={40} height={40} />
      </div>
        <h1>Outshine the <br />Competition
          <div>• Approval on Demand • No Overlays • Fair Lending •</div>
        </h1>

        <div className={styles.bannerCircle}>
          <div className={styles.bannerInnerCircle}>
          </div>


        </div>
        <img alt='' src="https://resources.swmc.com/swmc-images/WholesaleLending/curves.svg"
          className={styles.rightcurve} />

      </div>
  )
}

export default Banner