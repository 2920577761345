/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { Nav, Button } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import Classes from "./index.module.css";
import LeadFormModal from "../LeadFormModal";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu";

const NavWrapper = styled.div`
// .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
//     color: rgb(8, 63, 136) !important;
// }
.nav-item.dropdown{
  border:transparent!important;
}
.navbar-dark .navbar-nav .nav-link{
    color: white;
    z-index: 1200;
    // font-weight:600;
    white-space:nowrap;
    text-decoration: none;
}
// .nav-item.show > a{
//     color: #083F88!important;
// }
.navbar-expand-lg .navbar-nav .dropdown-menu{
    margin: 0px 0px 0 -30px !important;
}
._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
    font-weight: 600;
}
}
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color:transparent;
    border-left: 4px solid #083F88;
    border-right: 4px solid #083F88;
    transition: all 0.1s linear;
    border-radius:2px;
}
.dropdown-item{
    border-bottom:2px solid transparent;
}
.dropdown-item:focus, .dropdown-item:hover{
    background-color:transparent;
}
 .dropdown-item:hover{
    border-bottom:2px solid  #083F88 !important;
    text-decoration: none;
}
@media(max-width:767px){
    .dropdown-item{
        border-bottom:0px transparent !important;
    }
    .dropdown-item:hover{
        border-bottom: 0px transparent !important;
    }
}
#dropdown-item-button::after{
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA8ElEQVQ4jWNgGAUowHLOVSHjmc+4cMkbz3zGZTnnqhCyGBOMYTD/vsDvP2w7mf5/26E99SoPNs2M/79s+vWHbZ/xzJsiGAaw/PpbycDAYPKf4b8tFzPbRmSXGM98xsX0/+tmBgZGZ0YGBn1GBuZSuD4Y4z/DhxoGBj5VRgbGwP8MDE5Ql3hxsAj+Y/z/ZdN/BkYniErGrR9+/q+D6WNEdeYZVob/fCsZGRgDoZL7IIYzOEHo/+sZGD+Fn003+Y3VAGyGIADj1g+//gffyVP9iSKKbgB2Q7BrxmkAqiFMbLg0EwTGM8+wGs88w0qyxqEFAFtSY2S3+RTSAAAAAElFTkSuQmCC");
    // vertical-align: middle;
    vertical-align: -0.09em ;
    border:none;
    margin-top:5px;
}
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-color:white;
}
.btn-primary:focus {
    border-color: white;
}
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    background: #fff;
    color: #0481b5;
    border:1px solid #fff;
    box-shadow:none;
}
.channelsNav{
    .dropdown-submenu-container a.dropdown-submenu::after {
        position: absolute;
        right: 0;
        top: 0;
    }
.dropdown-menu{
    .dropdown-toggle::after {
        content: ">";
        vertical-align: middle;
        border: none;
        margin-top: 4px;
        font-weight: 600;
        color: #dddd;
        transform: rotate(0) !important;
    }
    padding-right:15px !important;
}
}
@media(min-width:1067px) and (max-width:1201px){
    .navbar-dark .navbar-nav .nav-link{
        font-size: 14px !important;
        font-weight:600;
    }
    }
    @media(min-width:992px) and (max-width:1067px){
        .navbar-dark .navbar-nav .nav-link{
            font-size: 13px !important;
            font-weight: 500;
            padding: 10px 5px;
        }
        .eEKJvu ._3yQ6ipwnXEJX5uUyBeOBFR.btn.btn-primary.btn-md {
            font-weight: 600;
            font-size: 14px;
        }
        }
@media(max-width:992px){
.navbar-dark .navbar-toggler{
background-color:#00AAE4;
// border-color:blue;
}
`;

const CustomNavbar = ({ location }) => {
  const [logoImage, setLogoImage] = useState("");

  const [show, setShow] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);

  const handleShowLeadFormModal = () => {
    const element = document.getElementById("apply-scroll");
    const offset = 80; // height of the fixed navbar
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;
  
    // Scroll to the desired position
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  
    // Close the navbar toggler if it's open (for mobile view)
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");
  
    if (navbarCollapse.classList.contains("show")) {
      navbarToggler.click(); // This simulates a click on the toggler to close it
    }
  };
  
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e) => {
    setShow1(!show1);
  };
  const hideDropdown1 = (e) => {
    setShow1(false);
  };
  const [show2, setShow2] = useState(false);
  const showDropdown2 = (e) => {
    setShow2(!show2);
  };
  const hideDropdown2 = (e) => {
    setShow2(false);
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setLogoImage(
        "https://resources.swmc.com/swmc-images/Homepage/New/SunWest41YearsofTrust-Blue.png"
      );
    } else {
      setLogoImage(
        "https://resources.swmc.com/swmc-images/minified/SunWest41YearsofTrust-White.png"
      );
    }
  }, []);

  return (
    <div>
      <NavWrapper>
        <Navbar
          collapseOnSelect
          expand="lg"
          fixed="top"
          variant="dark"
          className={Classes.homepageNav}
        >
          <Navbar.Brand className="d-flex">
            <a href="https://www.swmc.com" target="_blank" rel="noreferrer">
              <img
                src={logoImage}
                className={Classes.main_logo}
                alt="swmc-logo"
              />
            </a>
            <div className={Classes.UserWayAccessibilitydesktop}>
            <UserWayAccessibilityMenu color="black" width={40} height={40} />
            </div>
            
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" style={{ margin: "auto" }} />
            <Nav className={Classes.nav_temp}>
              <NavDropdown
                title="About"
                id="collasible-nav-dropdown"
                className={Classes.dropdown}
                show={show}
                onClick={showDropdown}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/our-history"
                  onClick={hideDropdown}
                >
                  Our History
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/why-sunwest"
                  onClick={hideDropdown}
                >
                  Success Stories
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/careers"
                  onClick={hideDropdown}
                >
                  Careers
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/contact-us"
                  onClick={hideDropdown}
                >
                  Contact Us
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Loan Tools"
                id="collasible-nav-dropdown"
                show={show1}
                onClick={showDropdown1}
                onMouseEnter={showDropdown1}
                onMouseLeave={hideDropdown1}
              >
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/angelai/calculators"
                  onClick={hideDropdown1}
                >
                  Calculators
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/loan-programs"
                  onClick={hideDropdown1}
                >
                  Loan Products
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.swmc.com/blog/"
                  target="_blank"
                  rel="noopener"
                  onClick={hideDropdown1}
                  className={Classes.external}
                >
                  Blog
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/"
                  rel="noopener"
                  target="_blank"
                  className={Classes.external}
                  onClick={hideDropdown1}
                >
                  Glossary
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/faq"
                  onClick={hideDropdown1}
                >
                  FAQ
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/theprocessor"
                  onClick={hideDropdown1}
                >
                  The Processor
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/turn-time-commitment"
                  onClick={hideDropdown1}
                >
                  Turn Time Commitment
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://askangel.ai/"
                  target="_blank"
                  rel="noopener"
                  onClick={hideDropdown1}
                  className={Classes.external}
                >
                  AskAngel.Ai
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/priceengine"
                  onClick={hideDropdown1}
                >
                  Pricing Engine
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://www.swmc.com/wholesale-lending"
              >
                Wholesale
              </Nav.Link>
              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://www.swmc.com/correspondent-lending"
              >
                Correspondent
              </Nav.Link>

              <NavDropdown
                title="Client Login"
                id="collasible-nav-dropdown"
                show={show2}
                onClick={showDropdown2}
                onMouseEnter={showDropdown2}
                onMouseLeave={hideDropdown2}
              >
                <NavDropdown.Item
                  href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=CMOL"
                  target="_blank"
                  rel="noopener"
                  className={Classes.external}
                  onClick={hideDropdown2}
                >
                  Check My Loan Progress
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://sunwestmortgage.myloaninfo.com/loanmanager/login/"
                  target="_blank"
                  rel="noopener"
                  className={Classes.external}
                  onClick={hideDropdown2}
                >
                  Make a Payment
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/loss-mitigation"
                  onClick={hideDropdown2}
                >
                  Servicing Portal
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/covid19-relief"
                  onClick={hideDropdown2}
                >
                  COVID-19 Relief
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/property-insurance"
                  onClick={hideDropdown2}
                >
                  Property Insurance
                </NavDropdown.Item>
                {/* <NavDropdown.Item href as={NavLink} href='/login'>Partner Login</NavDropdown.Item> */}
                <NavDropdown.Item
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.swmc.com/sunsoft-login"
                  onClick={hideDropdown2}
                >
                  ReverseSoft Login
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://www.swmc.com/login"
              >
                Partner Login
              </Nav.Link>
              <Nav.Link
                target="_blank"
                rel="noreferrer"
                href="https://www.swmc.com/priceengine"
              >
                Pricing Engine
              </Nav.Link>

              <Button
                className={Classes.homepageButtonApply}
                variant="primary"
                size="md"
                onClick={handleShowLeadFormModal}
                type="submit"
              >
                Broker<br/> Application
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </NavWrapper>
     
    </div>
  );
};

export default CustomNavbar;
